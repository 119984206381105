<template>
    <div class="countries-container">
        <DefaultDropdown
            class="counties-dropdown"
            :label="(!multiple && label) || ''"
            :style-modifier="styleModifier"
            :active-case="activeCase"
            :border-color="borderColor"
            flexible-label
            :placeholder="placeholder"
            ref="dropdown"
            :required="required"
            :cases="[]"
        >
            <template v-if="tooltip" #tooltip>
                <alert-with-question>
                    <default-title
                        :weight="500"
                        font-family="Inter, sans-serif"
                        class="mb-5"
                        v-html="label"
                    ></default-title>
                    <default-description font-family="Inter, sans-serif" v-html="tooltip"></default-description>
                </alert-with-question>
            </template>
            <template #modal>
                <DefaultPhoneCountries
                    :with-absent="withAbsent"
                    @select="updateValue"
                    :cases="cases"
                    :exclude="format && format.exclude_countries"
                    :include="format && format.include_countries"
                    :selected-countries="multiple && country"
                    :iso2="country && (country.iso2 || country.iso_2)"
                />
            </template>
        </DefaultDropdown>
        <div class="custom-scroll mt-10 overflow-auto" style="max-height: 125px" v-if="multiple && country.length">
            <div class="options-list-item mr-8" :key="index" v-for="(country, index) in country">
                <CloseButtonSm @close="removeFromList(country)" class="mr-10" :danger-accent="true" />
                {{ country.name }}
            </div>
        </div>
    </div>
</template>

<script>
import AlertWithQuestion from "../../Alerts/AlertWithQuestion.vue"
import CloseButtonSm from "../../Buttons/CloseButtonSm.vue"
import DefaultPhoneCountries from "../../Forms/country/DefaultCountriesList.vue"
import DefaultDropdown from "../../Forms/DefaultDropdown.vue"
import DefaultDescription from "../../Typography/DefaultDescription.vue"
import DefaultTitle from "../../Typography/DefaultTitle.vue"

export default {
    props: {
        label: {
            type: String,
            default: ""
        },
        format: {
            type: [Object, Array],
            default: null
        },
        validation: {
            type: [Object, Array],
            default: null
        },
        borderColor: {
            type: String,
            default: "#E0E0EC"
        },
        required: {
            type: Boolean,
            default: false
        },
        tooltip: {
            type: String,
            default: ""
        },
        country: {
            type: [Object, Array, String],
            default: null
        },
        placeholder: {
            type: String,
            default: ""
        },
        cases: {
            type: [Object, Array],
            default: null
        },
        withAbsent: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        styleModifier: {
            type: [String, Array],
            default: ""
        }
    },
    model: {
        prop: "country",
        event: "update"
    },
    name: "DefaultCountriesDropdown",
    components: {
        CloseButtonSm,
        DefaultPhoneCountries,
        DefaultTitle,
        AlertWithQuestion,
        DefaultDescription,
        DefaultDropdown
    },
    data() {
        return {
            isModalVisible: false
        }
    },
    methods: {
        removeFromList(country) {
            this.$emit(
                "update",
                this.country.filter(c => c !== country)
            )
        },
        updateValue(value) {
            if (!this.multiple) {
                this.$refs.dropdown.hide()
                this.$emit("update", value)
            } else {
                if (this.country.find(c => c.iso2 === value.iso2)) {
                    this.$emit("update", [...this.country.filter(c => c.iso2 !== value.iso2)])
                } else {
                    this.$emit("update", [...this.country, value])
                }
            }
        }
    },
    computed: {
        activeCase() {
            if (this.multiple && this.label) {
                return this.label
            }
            return this.country || (this.t && this.$store && this.t("settings.not_selected"))
        }
    }
}
</script>

<style lang="sass" scoped>
.counties-dropdown
    &::v-deep
        .default-countries
            max-width: 100%
            position: relative
            padding: 0
            box-shadow: none
</style>
